<template>
  <Dialog style="width: 40%" :header="from.videoId?'编辑':'添加'"
          v-model:visible="show" @hide="hideDialog">
    <div class="p-field">
      <label>课程名称：</label>
      <InputText v-model="from.className" type="text"/>
    </div>
    <div class="p-field">
      <label>课程时间：</label>
      <Calendar v-model="from.classTime" class="p-mr-2" placeholder="发布时间"
                :dateFormat="'yy-mm-dd'"
                :show-button-bar="true"/>
    </div>
    <div class="p-field">
      <label>所属行业：</label>
      <Dropdown v-model="from.industry" class="p-mr-2" :options="industryList"
                optionLabel="name" option-value="name" :show-clear="true"
                placeholder="所属行业"/>
    </div>
    <div class="p-field">
      <label>课程链接：</label>
      <InputText v-model="from.classLink" type="text"/>
    </div>
    <div class="img">
      <label>录播封面：</label>
      <input style="display: none" multiple="multiple" accept="image/*" @change="selectImg" id="uploadButton"
             class="upload" type="file">
      <div class="uploadImg" @click="upload">
        <img id="img" style="display: none" src="">
        <img src="../../../assets/layout/images/add.png" id="ico" class="ico">
      </div>
    </div>
    <div class="buttons">
      <Button @click="show=false" class="p-mr-2">取消</Button>
      <Button @click="submit">保存</Button>
    </div>
    <Toast/>
  </Dialog>
</template>

<script>
export default {
  name: "addOrUpdate",
  data() {
    return {
      show: false,
      industryList: [],
      from: {
        videoId: null,
        className: null,
        classTime: null,
        industry: null,
        classLink: null,
        classPic: null
      }
    }
  },
  mounted() {

  },
  methods: {
    init(data) {
      this.show = true
      this.getIndustryList()
      if(data.videoId!=null) {
        console.log(data)
        this.from = data
        this.from.classTime = new Date(data.classTime).toLocaleDateString()
        setTimeout(()=>{
          let img = document.getElementById('img')
          img.style.display = 'block'
          document.getElementById('ico').style.display = 'none'
          img.src =this.$global_msg.imgurl + data.classPic
        },300)
      }
    },
    hideDialog() {
      this.$emit('close')
    },
    submit(){
      this.from.classTime = new Date(this.from.classTime)
      this.$http.post('/video/addOrUpdate',this.from).then(()=>{
        this.$toast.add({severity: 'success', summary: '成功', life: 3000})
        setTimeout(()=>{
          this.show = false
        },2000)
      })
    },
    getIndustryList() {
      this.$http.get('/dictionary/selectValue', {params: {key: 'suoshuhangye'}}).then((res) => {
        let list = res.data.myValue.split(",");
        let resList = []
        for (let i in list) {
          let item = {}
          item.name = list[i]
          resList.push(item)
        }
        this.industryList = resList;
      })
    },
    upload() {
      document.getElementById("uploadButton").click()
    },
    selectImg() {
      document.getElementById('ico').style.display = 'none'
      let img = document.getElementById('img')
      img.style.display = 'block'
      let fileReader = new FileReader();
      let uploadFile = document.getElementById("uploadButton").files[0]
      fileReader.readAsDataURL(uploadFile)
      fileReader.onload = function () {
        img.src = this.result
      }
      let param = new FormData()
      param.append('file', uploadFile)
      let config = {
        headers: {'Content-Type': 'multipart/form-data'}
      };
      this.$http.post('/file/uploadOne', param, config).then((res) => {
        this.from.classPic = res.data
      })
    },
  }
}
</script>

<style scoped>
.img {
  display: flex;
}

.img label {
  width: 200px;
  text-align: right;
}

.uploadImg {
  width: 150px;
  height: 150px;
  border: 1px dashed #dfdfdf;
}

#img {
  width: 150px;
  height: 150px;
}

.ico {
  width: 50px;
  height: 50px;
  margin: 50px;
}

.p-field label {
  width: 200px;
  text-align: right;
}

.buttons {
  width: 60%;
  margin: 30px 20% 10px;
  display: flex;
  justify-content: flex-end;
}
</style>