<template>
  <Breadcrumb :home="home" :model="items"/>
  <div class="card p-mt-3">
    <data-table :value="dataList">
      <template #header>
        <div class="p-d-flex">
          <Button class="p-mr-2" @click="addOrUpdate">添加录播</Button>
          <InputText v-model="name" class="p-mr-2" type="text" placeholder="课程名称"/>
          <Calendar v-model="classTime" class="p-mr-2" placeholder="发布时间" dateFormat="dd-mm-yy"
                    :show-button-bar="true"/>
          <Dropdown v-model="industry" class="p-mr-2" :options="industryList"
                    optionLabel="name" option-value="name" :show-clear="true"
                    placeholder="所属行业"/>
          <Dropdown v-model="author" class="p-mr-2" type="text"
                    :options="userList"
                    optionLabel="name" option-value="id" :show-clear="true"
                    placeholder="发布人"/>
          <Button @click="getData">查询</Button>
        </div>
      </template>
      <template #empty>
        <p style="text-align: center">无数据</p>
      </template>
      <Column field="videoId" header="序号"></Column>
      <Column field="className" header="活动课程名称"></Column>
      <Column field="classTime" header="课程时间">
        <template #body="o">
          {{ new Date(o.data.classTime).toLocaleDateString() }}
        </template>
      </Column>
      <Column field="industry" header="所属行业"></Column>
      <Column field="classLink" header="课程查看">
        <template #body="o">
          <a :href="'https://'+o.data.classLink" target="_blank">{{o.data.classLink}}</a>
        </template>
      </Column>
      <Column header="操作">
        <template #body="o">
          <Button @click="addOrUpdate(o.data)" class="p-button-sm p-mr-2">编辑</Button>
          <Button @click="deleteOne(o.data.videoId)" class="p-button-sm p-mr-2 p-button-danger">删除</Button>
        </template>
      </Column>
    </data-table>
    <Paginator @page="changePage" :rows="page.size" :totalRecords="total" :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录"
    template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"></Paginator>
  </div>
  <Toast/>
  <add-or-update v-if="addOrUpdateDisplay" ref="addOrUpdate" v-on:close="closeDialog"></add-or-update>
</template>

<script>
import addOrUpdate from "@/views/platform/trainingVideo/addOrUpdate";

export default {
  name: "videoList",
  components: {addOrUpdate},
  data() {
    return {
      home: {icon: 'pi pi-home', to: '/'},
      items: [
        {label: '培训录播内容管理'},
        {label: '录播列表'},
      ],
      userList:[],
      addOrUpdateDisplay: false,
      industryList: [],
      name: null,
      author: null,
      classTime: null,
      industry: null,
      total: null,
      dataList: [],
      page: {
        current: 1,
        size: 10,
      }
    }
  },
  mounted() {
    this.getData()
    this.getIndustryList()
    this.getUserList()
  },
  methods: {
    getData() {
      this.$http.get('/video/getVideoList', {
        params: {
          current: this.page.current,
          size: this.page.size,
          name: this.name,
          author: this.author,
          classTime: this.classTime,
          industry: this.industry
        }
      }).then((res) => {
        this.total = res.data.total
        this.dataList = res.data.records
      })
    },
    deleteOne(id){
      this.$http.post('/video/delete/'+id).then(()=>{
        this.$toast.add({severity: 'success', summary: '删除成功', life: 3000})
        this.getData()
      })
    },
    addOrUpdate(data) {
      this.addOrUpdateDisplay = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(data)
      })
    },
    closeDialog() {
      this.addOrUpdateDisplay = false
      this.getData()
    },
    changePage(event) {
      this.page.size = event.rows
      this.page.current = event.page + 1
      this.getData()
    },
    getIndustryList() {
      this.$http.get('/dictionary/selectValue', {params: {key: 'suoshuhangye'}}).then((res) => {
        let list = res.data.myValue.split(",");
        let resList = []
        for (let i in list) {
          let item = {}
          item.name = list[i]
          resList.push(item)
        }
        this.industryList = resList;
      })
    },
    getUserList() {
      this.$http.get('/adminUser/getAdminUserList').then((res)=>{
        console.log(res.data)
        this.userList = res.data
      })
    }
  }
}
</script>

<style scoped>

</style>